<template>
  <div class="zxfwlist">
    <div class="header">
      <div class="h1">专家咨询</div>
      <div class="h2">专家把脉  共创辉煌</div>
    </div>
    <div class="content">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="mbx">
        <el-breadcrumb-item  :to="{ path: '/zjzx' }">专家咨询</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/zjzxlist' }">专家列表</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="search">
<!--        <avue-search :option="option"   @change="loadlist(true)" -->
<!--                     v-model="form">-->
<!--        </avue-search>-->
<!--        <div style="text-align: right;padding: 0 20px 20px 0">-->
<!--          <el-button type="primary" size="mini" @click="loadlist(true)">筛选</el-button>-->
<!--          <el-button  size="mini" @click="reset">重置</el-button>-->
<!--        </div>-->
      </div>
      <div>
        <div class="flex lists" @click="goxq(list)" v-for="(list,index) in arr">
            <div class="img">
              <el-avatar :size="108" :src="list.avatar"></el-avatar>
            </div>
            <div class="flex-1">
              <div class="title">{{list.name}}</div>
              <div class="neirong">擅长方向： {{list.major}}</div>
              <div class="neirong col1"><i class="dingwei el-icon-location"></i>{{list.city}}</div>
            </div>
            <div>
              <el-button type="primary" class="btn" style="background: #0078F2 ">查看详情</el-button>
            </div>
        </div>
        <div class="fenye">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              layout="total, sizes, prev, pager, next, jumper"
              :current-page="page.current"
              :page-sizes="[10, 50, 100, 200]"
              :page-size="page.size"
              :total="page.total">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


import {expertindex, expertpage} from "../../request/moudle/zjzx";

export default {
  name: "gonggaoxq",
  components:{
  },
  data(){
    return  {
      form: {
      },
      page:{
        current:1,
        size:10,
        total:0
      },
      option: {
        column: [ {
          label: '专家类型',
          prop: 'expertName',
          props: {
            label: 'name',
            value: 'code',
          },
          multiple: false,
          dicData:[],
        }]
      },
      arr:[]
    }
  },
  created() {
    this.loadconfig()
    this.loadlist()
  },
  methods:{
    goxq(item){
      this.$router.push({
        path:'/zjzxxq',
        query:{
          id:item.id
        }
      })
    },
    loadconfig(){
      this.$api.zjzx.expertindex().then((res)=>{
        const levelId=this.findObject(this.option.column, 'expertName')
        levelId.dicData=res.data.expertTypeList
        levelId.dicData.unshift({ name: '不限', code: '' })
      })
    },
    loadlist(bol){
      if(bol){
        this.page.current=1
      }
      const obj={
        ...this.form
      }
      obj.current=this.page.current
      obj.size=this.page.size
      this.$api.zjzx.expertpage(obj).then((res)=>{
        this.arr=res.data.records
        this.page.total=res.data.total
      })
    },
    reset(){
      for (const val of this.option.column){
        if(val.multiple){
          this.form[val.prop]=['']
        }else {
          this.form[val.prop]=''
        }
      }
    },
    handleSizeChange(val){
      this.page.size=val
      this.loadlist()
    },
    handleCurrentChange(val) {
      this.page.current=val
      this.loadlist()
    },
  }
}
</script>

<style lang="scss" scoped>
.col1{
  color: #C4C4C4!important;
}
.fenye{
  text-align: center;
  padding: 30px 0;
}
  .zxfwlist{
     background: #F5F5F5;
    .header{
      height: 180px;
      background: url("../../assets/banner/zjzx.png") no-repeat;
      background-size: 100% 100%;
      padding-top: 90px;
      text-align: center;
      color: #fff;
      .h1{
        font-size: 42px;
        font-weight: 500;
      }
      .h2{
        font-size: 22px;
        line-height: 80px;
      }
    }
    .content{
      width: 1200px;
      margin: 20px auto;
      .search{
        padding: 25px;
      }
      .lists{
        border-top: 1px solid #eeeeee;
        padding: 14px;
        background: #fff;
        .btn{
          margin-top: 55px;
          margin-left: 40px;
        }
        .img{
          width: 108px;
          height: 108px;
          margin-right: 35px;
        }
        .title{
          font-size: 18px;
          font-weight: 520;
          line-height: 41px;
        }
        .neirong{
          color: #292929;
          line-height: 28px;
        }
        .dingwei{
          color: red;
          font-size: 25px;
          vertical-align: middle;
          margin-right: 5px;
        }
      }
    }
  }
</style>